import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { decode } from "html-entities";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ReactGA from "react-ga4";
// components
import CollapsibleBox from "../../components/ui/CollapsibleBox";
import AppAutocomplete from "../../components/form/AppAutocomplete";
import ThreeDotsLoader from "../../components/ui/ThreeDotsLoader";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
// hooks
import { useActions } from "../../common/hooks/useActions";
import { useDebounce } from "../../common/hooks/useDebounce";
// helpers
import { getSearchOffersTitle } from "./helpers/getSearchOffersTitle";
import { getOptionsForSearch } from "./helpers/getOptionsForSearch";
import { mockOfferCodeKeyword } from "./helpers/constant";
import { getCurrentDevice } from "../../common/utils/helpers/currentDeviceHelpers/getCurrentDevice";
import { isFilterOffersActive } from "./helpers/isFilterOffersActive";
// actions
import { exploreActionCreators } from "./exploreModule";

interface ExploreSidebarProps {
  keywordParamDefault: string | null;
  categoryParamDefault: string | null;
  resetFilters: () => void;
  device: string;
}

const boxStyle = { mb: 3 };
const queryLimit = 3;

export default function ExploreSidebar({
  keywordParamDefault,
  categoryParamDefault,
  resetFilters,
  device
}: ExploreSidebarProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoriesState, setCategoriesState] = useState<string[]>([]);

  const language = localStorage.getItem("i18nextLng");

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const offersSearchResults = useSelector((state: any) => state.explore.offersSearchResults);
  const isLoadingSearchResults = useSelector((state: any) => state.explore.isLoadingSearchResults);
  const selectedOfferFromSearch = useSelector((state: any) => state.explore.selectedOfferFromSearch);
  const queryOffers = useSelector((state: any) => state.explore.queryOffers);
  const totalResultsSearchedOffers: number = useSelector((state: any) => state.explore.totalResultsSearchedOffers);
  const showSearchResultsLoader = useSelector((state: any) => state.explore.showSearchResultsLoader);
  const categories = useSelector((state: any) => state.explore.categories);

  // actions
  const searchOffers = useActions(exploreActionCreators?.searchOffersAutocompleteAction, []);
  const resetSearchOffersResults = useActions(exploreActionCreators?.resetSearchOffersResultsAction, []);
  const getOffers = useActions(exploreActionCreators?.getOffersAction, []);
  const setSelectedOfferFromSearch = useActions(exploreActionCreators?.setSelectedOfferFromSearchAction, []);
  const setQueryOffers = useActions(exploreActionCreators?.setQueryOffersAction, []);
  const setSearchedQuery = useActions(exploreActionCreators?.setSearchedQueryAction, []);
  const setShowSearchResultsLoader = useActions(exploreActionCreators?.setShowSearchResultsLoaderAction, []);

  useEffect(() => {
    // if keyword comes from direct link, we should save it in redux
    if (keywordParamDefault) {
      setSelectedOfferFromSearch({ name: keywordParamDefault, offer_code: mockOfferCodeKeyword });
      setSearchedQuery(keywordParamDefault);
    }
  }, [language]);

  useEffect(() => {
    const selectedCategories = categories
      ?.filter((category: any) => category.selected) // Only take the categories that are selected (true)
      ?.map((category: any) => category.value);

    setCategoriesState(selectedCategories);
  }, [categories]);

  // reset Categories state
  useEffect(() => {
    if (!categoryParamDefault && categories?.length === 0) {
      setCategoriesState([]);
    }
  }, [categoryParamDefault]);

  const callSearchOffers = () => {
    if (queryOffers?.length > 2) {
      searchOffers(queryOffers);
    }
  };

  const debounceCallSearchOffers = useDebounce(callSearchOffers, 400);

  const handleSearchOffersInputChange = (event: any) => {
    resetSearchOffersResults();

    if (event?.type === "change") {
      // Turn on three dots loader
      setShowSearchResultsLoader(true);
      debounceCallSearchOffers();
      setQueryOffers(event.target.value);
    }
  };

  const onChangeSearchOfferAutoComplete = (event: any, value: any, reason: string) => {
    // disable click on option if there are no data from API (offersSearchResults)
    if (reason === "selectOption") {
      setSelectedOfferFromSearch(value);
      if (value?.offer_code === mockOfferCodeKeyword) {
        // go here when user clicks on first ("search for...") item from autocomplete list --> update the list
        setSearchParams({ keyword: value?.name, categories: categoryParamDefault ? categoryParamDefault : "" });

        getOffers(
          {
            keyword: value?.name,
            offset: 1,
            categories: categoryParamDefault
              ? [categoryParamDefault]?.[0].split(",").map(category => category.trim())
              : categoriesState
          },
          device
        );
        setSearchedQuery(value?.name);

        ReactGA.event({
          category: "Search Offers",
          action: `${hotelInfo.code}-search-offer-keyword`,
          label: `${value?.name}`
        });
        ReactGA.gtag("event", "search", {
          search_term: value?.name
        });
      } else {
        ReactGA.event({
          category: "Search Offers",
          action: `${hotelInfo.code}-search-offer-click`,
          label: `${value?.name}`
        });
        navigate(`offers/${value?.offer_code}`, {
          state: {
            offer_code: value?.offer_code,
            name: value?.name
          }
        });
      }
    }
  };

  const renderSearchCollapsibleTitle = (): string => {
    return getSearchOffersTitle(
      t("explore.search.titleForCollapsibleSearchBox"),
      isFilterOffersActive(selectedOfferFromSearch?.offer_code)
    );
  };

  const handleChangeCategoryCheckbox = (category: any, checked: boolean) => {
    const categoriesStateCopy = [...categoriesState];

    let updatedCategoriesState;

    if (checked) {
      if (!categoriesStateCopy.includes(category)) {
        updatedCategoriesState = [...categoriesStateCopy, category]; // Push the new category
      } else {
        updatedCategoriesState = categoriesStateCopy; // No change if already exists
      }
    } else {
      updatedCategoriesState = categoriesStateCopy.filter(
        categorySelected => categorySelected !== category // Remove the category
      );
    }

    if (keywordParamDefault) {
      setSearchParams({
        keyword: keywordParamDefault ?? "",
        categories: updatedCategoriesState.length ? updatedCategoriesState.join(",") : ""
      });
    } else {
      setSearchParams({
        categories: updatedCategoriesState.length ? updatedCategoriesState.join(",") : ""
      });
    }
    getOffers(
      {
        keyword: keywordParamDefault,
        offset: 1,
        categories: updatedCategoriesState
      },
      device
    );
    setCategoriesState(updatedCategoriesState);
  };

  return (
    <Box className="explore-sidebar">
      <Box sx={{ ...boxStyle }}>
        <CollapsibleBox
          title={t("explore.titleForCollapsibleStayBox")}
          isExpanded={true}
          backgroundColor={hotelInfo?.style?.page?.box_color}
          dataAttribute="your-stay"
        >
          <Trans>
            <Box sx={{ color: theme.palette.primary.dark, fontSize: { xs: "14px", md: "15px" } }}>
              {decode(
                t("explore.textForCollapsibleStayBox", {
                  hotel: decode(hotelInfo?.name),
                  city: decode(hotelInfo?.address?.city)
                })
              )}
            </Box>
          </Trans>
        </CollapsibleBox>
      </Box>
      <Box sx={{ ...boxStyle }}>
        <CollapsibleBox
          title={renderSearchCollapsibleTitle()}
          isExpanded={getCurrentDevice() !== "phone"}
          backgroundColor={hotelInfo?.style?.page?.box_color}
          dataAttribute="search-box"
        >
          <>
            <AppAutocomplete
              // TBD: Query offers added for handling Clear Icon, check logic of input value source and of setting input value
              queryOffers={queryOffers}
              queryLimit={queryLimit ? queryLimit : 0}
              showSearchResultsLoader={showSearchResultsLoader}
              loading={isLoadingSearchResults && queryOffers?.length >= queryLimit}
              id="searchOffers"
              name="searchOffers"
              freeSolo={true}
              size="small"
              onInputChange={handleSearchOffersInputChange}
              onChangeAutoComplete={onChangeSearchOfferAutoComplete}
              handleClearButton={resetFilters}
              noOptionsText={
                queryOffers?.length >= queryLimit ? t("explore.search.noResults") : t("explore.search.typeToSearch")
              }
              label={
                queryOffers?.length > 0 || (selectedOfferFromSearch && !selectedOfferFromSearch?.reset)
                  ? ""
                  : t("explore.search.typeToSearch")
              }
              options={getOptionsForSearch(queryOffers, offersSearchResults, mockOfferCodeKeyword)}
              filterOptions={(options: any) => options}
              getOptionLabel={(option: any) => `${option?.name}`}
              value={selectedOfferFromSearch}
              renderOption={(props: any, option: any) => {
                const loading = isLoadingSearchResults && queryOffers?.length >= queryLimit;

                const quote: false | string = option.offer_code === mockOfferCodeKeyword && `"`;
                const isLastOption: boolean = props["data-option-index"] === offersSearchResults?.length;
                return (
                  <Box key={option.offer_code}>
                    {loading || (showSearchResultsLoader && queryOffers?.length >= queryLimit) ? (
                      <>
                        <Box {...props}>
                          <div className="explore-sidebar__autocomplete-list" data-cy="autocomplete-list">
                            {option.offer_code === mockOfferCodeKeyword && (
                              <>
                                <span
                                  className="explore-sidebar__autocomplete-list__search-for"
                                  data-cy="autocomplete-list-label"
                                >
                                  {t("explore.search.searchFor")}
                                </span>
                                <span
                                  className="explore-sidebar__autocomplete-list__item"
                                  data-cy={`autocomplete-list-item-${option.name}`}
                                >
                                  {quote}
                                  {option.name}
                                  {quote}
                                </span>
                              </>
                            )}
                          </div>
                        </Box>
                        {offersSearchResults?.length === 0 && (
                          <span className="explore-sidebar__autocomplete-list__footer">
                            <ThreeDotsLoader />
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <Box {...props}>
                          <div className="explore-sidebar__autocomplete-list" data-cy="autocomplete-list">
                            {option.offer_code === mockOfferCodeKeyword ? (
                              <>
                                <span
                                  className="explore-sidebar__autocomplete-list__search-for"
                                  data-cy="autocomplete-list-label"
                                >
                                  {t("explore.search.searchFor")}
                                </span>
                                <span
                                  className="explore-sidebar__autocomplete-list__item"
                                  data-cy={`autocomplete-list-item-${option.name}`}
                                >
                                  {quote}
                                  {option.name}
                                  {quote}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="explore-sidebar__autocomplete-list__item"
                                  data-cy={`autocomplete-list-item-${option.name}`}
                                >
                                  {quote}
                                  {option.name}
                                  {quote}
                                </span>
                                <span
                                  className="explore-sidebar__autocomplete-list__subtitle"
                                  data-cy="list-item-subtitle"
                                >
                                  {option?.provider_name || "missing field"}{" "}
                                </span>
                              </>
                            )}
                          </div>
                        </Box>
                        {isLastOption && !loading && (
                          <span
                            className="explore-sidebar__autocomplete-list__footer label"
                            data-cy="autocomplete-list-footer"
                          >
                            {totalResultsSearchedOffers} {totalResultsSearchedOffers > 1 ? "results" : "result"}
                          </span>
                        )}
                      </>
                    )}
                  </Box>
                );
              }}
            />
            <Box mt={3}>
              {categories?.map((category: any, index: number) => {
                return (
                  <Box sx={{ mt: 2 }}>
                    <FormGroup key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={false}
                            checked={category?.selected}
                            onChange={() => handleChangeCategoryCheckbox(category?.value, !category?.selected)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={category?.label}
                        sx={{
                          ".MuiButtonBase-root": {
                            padding: "0px 9px  !important"
                          }
                        }}
                      />
                    </FormGroup>
                  </Box>
                );
              })}
            </Box>
          </>
        </CollapsibleBox>
      </Box>
    </Box>
  );
}
